import {
  __BaseInputProps as MantineInputBaseProps,
  BoxProps as MantineBoxProps,
  ColorSwatch as MantineColorSwatch,
  ElementProps,
  InputBase as MantineInputBase,
  InputPlaceholder as MantineInputPlaceholder
} from '@mantine/core';
import cx from 'clsx';
import isNil from 'lodash/isNil';
import React from 'react';

import { ColorPickerElement } from '@/common/components/Color/_Picker';
import {
  ColorPickerIcon,
  ColorPickerIconVariants
} from '@/common/components/Color/ColorPickerIcon';
import { Center } from '@/common/components/Display/Center';
import { Popover } from '@/common/components/Popover';
import { useUncontrolled } from '@/common/hooks/useUncontrolled';

import { ActionButton } from '../../Button';
import { EditIcon } from '../../Icons/EditIcon';
import {
  FormInputWrapper,
  IAmFormInputRenderProps
} from '../InputWrapper/FormInputWrapper';
import classes from './FormColorInput.module.css';

export interface FormColorInputProps
  extends MantineBoxProps,
    Omit<
      MantineInputBaseProps,
      | 'labelProps'
      | 'descriptionProps'
      | 'withAsterisk'
      | 'size'
      | 'component'
      | 'pointer'
      | 'inputContainer'
      | 'rightSection'
      | 'leftSection'
      | 'leftSectionPointerEvents'
      | 'rightSectionPointerEvents'
    >,
    Omit<IAmFormInputRenderProps<HTMLInputElement>, 'onFocus'> {
  value?: string;
  defaultValue?: string;
  withinPortal?: boolean;
  placeholder?: string;
  className?: string;
  onChange?: (color: string) => void;
  onBlur?: ElementProps<'button'>['onBlur'];
  onClick?: ElementProps<'button'>['onClick'];
  name?: string;
  emptyColor?: string;
  clearEnabled?: boolean;
  opened?: boolean;
  defaultOpened?: boolean;
  iconVariant?: ColorPickerIconVariants;
}
export const FormColorInput = React.forwardRef<
  HTMLButtonElement,
  FormColorInputProps
>(
  (
    {
      descriptionProps,
      inputContainer,
      showSkeleton,
      labelProps,
      required,
      size,
      disabled,
      error,
      hideErrorMessage,
      value: valueProp,
      defaultValue,
      placeholder,
      onChange,
      onBlur,
      onClick,
      autoFocus,
      opened: openedProp,
      defaultOpened,
      withinPortal,
      className,
      iconVariant,
      clearEnabled,
      emptyColor = '#FFFFFF',
      ...rest
    },
    forwardedRef
  ) => {
    const [opened, setOpened] = useUncontrolled({
      defaultValue: defaultOpened,
      value: openedProp
    });

    const [value, handleChange] = useUncontrolled({
      value: valueProp,
      defaultValue,
      finalValue: null,
      sanitiseValue: (v) => (v || '').trim(),
      onChange
    });

    const hasIcon = !isNil(iconVariant);

    return (
      <FormInputWrapper<HTMLButtonElement>
        ref={forwardedRef}
        error={error}
        hideErrorMessage={hideErrorMessage}
        labelProps={labelProps}
        descriptionProps={descriptionProps}
        inputContainer={inputContainer}
        size={size}
        autoFocus={autoFocus}
        showSkeleton={showSkeleton}
      >
        {(p, ref) => (
          <MantineInputBase
            ref={ref}
            {...rest}
            {...p}
            leftSectionPointerEvents="none"
            rightSectionPointerEvents="all"
            component="button"
            type="button"
            pointer
            className={cx(className, classes.root)}
            value={value}
            onBlur={onBlur}
            onClick={(e) => {
              if (disabled) return;
              onClick?.(e);
              setOpened(!opened);
            }}
            leftSection={
              <Center>
                {hasIcon && (
                  <ColorPickerIcon
                    variant={iconVariant}
                    size="calc(var(--input-height) * 0.6 - 7px)"
                  />
                )}
                <MantineColorSwatch
                  size={hasIcon ? 5 : 'calc(var(--input-height) * 0.6)'}
                  w="100%"
                  color={value || emptyColor}
                />
              </Center>
            }
            rightSection={
              <ActionButton
                size="calc(var(--input-height) * 0.7)"
                tooltip={{ label: 'Edit color' }}
                onClick={() => setOpened(!opened)}
              >
                <EditIcon />
              </ActionButton>
            }
            inputContainer={(i) => (
              <Popover
                withinPortal={withinPortal}
                classNames={{ dropdown: 'color-picker-popover-container' }}
                styles={{
                  dropdown: {
                    padding: '0.5rem'
                  }
                }}
                opened={opened}
                defaultOpened={defaultOpened}
                onChange={setOpened}
                target={() => i}
              >
                <ColorPickerElement
                  value={value}
                  clearEnabled={clearEnabled}
                  onChangeEnd={handleChange}
                  emptyColor={emptyColor}
                />
              </Popover>
            )}
            withAsterisk={required}
          >
            <MantineInputPlaceholder
              c={!placeholder && !!value ? 'inherit' : undefined}
            >
              {value || placeholder || emptyColor}
            </MantineInputPlaceholder>
          </MantineInputBase>
        )}
      </FormInputWrapper>
    );
  }
);

// todo remove this comment
