import {
  Textarea as MantineTextarea,
  TextareaProps as MantineTextareaProps
} from '@mantine/core';
import React, { forwardRef } from 'react';

import { useUncontrolled } from '@/common/hooks/useUncontrolled';

import { FormInputWrapper, IAmFormInputRenderProps } from '../InputWrapper';

export interface FormTextAreaProps
  extends Omit<
      MantineTextareaProps,
      | 'defaultValue'
      | 'value'
      | 'onChange'
      | 'labelProps'
      | 'descriptionProps'
      | 'withAsterisk'
      | 'size'
    >,
    IAmFormInputRenderProps<HTMLTextAreaElement> {
  //Override values to force string
  defaultValue?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>, value: string) => void;
  sanitiseValue?: (value: string) => string;
}

export const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  (props, forwardedRef) => {
    const {
      size,
      value: valueProp,
      defaultValue,
      sanitiseValue,
      onChange,
      required,
      onFocus,
      showSkeleton,
      selectAllTextOnFocus,
      autoFocus,
      inputContainer,
      descriptionProps,
      labelProps,
      error,
      hideErrorMessage,
      ...rest
    } = props;

    const [value, handleChange] = useUncontrolled<
      string,
      React.ChangeEvent<HTMLTextAreaElement>
    >({
      value: valueProp,
      defaultValue,
      finalValue: null,
      resolveValue: (e) => e.target.value,
      sanitiseValue,
      onChange
    });

    return (
      <FormInputWrapper<HTMLTextAreaElement>
        ref={forwardedRef}
        autoFocus={autoFocus}
        onFocus={onFocus}
        selectAllTextOnFocus={selectAllTextOnFocus}
        labelProps={labelProps}
        descriptionProps={descriptionProps}
        inputContainer={inputContainer}
        size={size}
        showSkeleton={showSkeleton}
        error={error}
        hideErrorMessage={hideErrorMessage}
      >
        {(p, ref) => (
          <MantineTextarea
            ref={ref}
            value={value}
            onChange={handleChange}
            withAsterisk={required}
            {...p}
            {...rest}
          />
        )}
      </FormInputWrapper>
    );
  }
);
