import isNil from 'lodash/isNil';
import { FieldValues } from 'react-hook-form';

import {
  asFloatOrUndefined,
  asNumberOrUndefined
} from '@/common/utils/NumberFunctions';

import { IAmFormHookField } from '../FormHookField';
import { FormNumberInput, FormNumberInputProps } from './FormNumberInput';

export interface FormHookNumberInputProps<
  TFieldValues extends FieldValues = any
> extends IAmFormHookField<TFieldValues>,
    Omit<
      FormNumberInputProps,
      'required' | 'name' | 'value' | 'defaultValue' | 'error' | 'disabled'
    > {
  isFloat?: boolean;
}

export function FormHookNumberInput<TFieldValues extends FieldValues = any>(
  props: FormHookNumberInputProps<TFieldValues>
) {
  const { hook, onBlur, onChange, isFloat, ...rest } = props;

  const value = isFloat
    ? asFloatOrUndefined(hook.field.value)
    : asNumberOrUndefined(hook.field.value);

  return (
    <FormNumberInput
      {...rest}
      required={hook.required}
      disabled={hook.disabled}
      name={hook.field.name}
      ref={hook.field.ref}
      onBlur={(e) => {
        hook.field.onBlur();
        onBlur?.(e);
      }}
      onChange={(v) => {
        const setUndefined = typeof v !== 'number';
        hook.setValue(hook.field.name, setUndefined ? undefined : (v as any), {
          shouldValidate: true,
          shouldDirty: true
        });
        onChange?.(v);
      }}
      value={isNil(value) ? '' : value}
      error={hook.showError ? hook.errorMessage : undefined}
    />
  );
}
